import { api } from "../utils/api"
import { store } from "../store"

class MediaPlayheadTracker {
  // eslint-disable-next-line space-before-function-paren
  constructor(mediaType, contentMetaData, chapter) {
    this.mediaType = mediaType
    this.contentMetaData = contentMetaData
    this.chapter = chapter
    this.previousTime = null
    this.currentTime = 0
    this.startingPoint = 0
    this.elapsedTime = 0

    // change this to disable logging
    this.shouldLog = true
  }

  timeUpdate(val) {
    if (this.previousTime === null) {
      this.previousTime = val
      this.startingPoint = val
    }

    this.currentTime = val
    this.previousTime = val
  }

  chapterUpdate(chapter) {
    this.chapter = chapter
  }

  fileEnded() {
    this.previousTime = null
  }

  destroy() {
    this._sendMediaViewIfNecessary({ isDestroyed: true })
    this._sendLastKnownMediaPlayheadPositionIfNecessary()
  }

  _sendMediaViewIfNecessary({ isDestroyed = false }) {
    this._calcElapsedTime()
    const isTimeHeadJumped = this._isTimeHeadJumped()

    if (isDestroyed || isTimeHeadJumped) {
      if (this.elapsedTime > 10) {
        this._sendMediaView()
      }

      this._reAssignStartingPoint()
    }
  }

  _calcElapsedTime() {
    this.elapsedTime = this.previousTime - this.startingPoint
  }

  _isTimeHeadJumped() {
    const timeDiff = Math.abs(this.currentTime - this.previousTime)
    return timeDiff > 3
  }

  _reAssignStartingPoint() {
    this.startingPoint = this.currentTime
  }

  _sendMediaView() {
    this._sendEvent({
      logType: 'mediaView',
      description: `user played media: ${this.contentMetaData.id}`,
      timestamp: Date.now(),
      elapsedSeconds: Math.round(this.elapsedTime),
    })
  }

  _getFileName () {
    return this.chapter?.fileName || ''
  }

  _sendLastKnownMediaPlayheadPositionIfNecessary () {
    if (this.previousTime !== null && Math.round(this.elapsedTime) > 10) this._sendLastKnownMediaPlayheadPosition()
  }

  _sendLastKnownMediaPlayheadPosition() {
    this._sendEvent({
      logType: 'lastKnownMediaPlayheadPosition',
      description: `users last known playhead position for content: ${this._getFileName()}`,
      playheadPosition: Math.round(this.currentTime),
      fileName: this._getFileName(),
      elapsedSeconds: Math.round(this.elapsedTime),
      chapterTitle: this.chapter ? this.chapter.displayName : "",
      chapterID: this.chapter ? this.chapter.id : "",
      timestamp: Date.now(),
    })
  }

  _commonPayload() {
    return {
      context: 'library',
      mediaType: this.mediaType,
      contentID: this.contentMetaData.id,
      contentProjectID: this.contentMetaData.projectID,
      contentName: this.contentMetaData.contentName,
    }
  }

  _sendEvent(additionalPayload) {
    if (!this.shouldLog) return
    let payload = {
      ...this._commonPayload(),
      ...additionalPayload,
    }
    api('remember/it', payload, true, store.state)
  }
}

export default MediaPlayheadTracker

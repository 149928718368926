<template>
  <b-aspect
    aspect="16:9"
    class="w-100 h-100"
    :style="`max-height: calc(100vh - ${$store.getters.fullScreenAsDefault ? '0px' : '152px'} !important;overflow-y: hidden !important;`"
  >
    <video
      ref="video"
      class="video-player__video video-js vjs-big-play-centered w-100 h-100"
      controls
      autoplay
      preload="auto"
      crossorigin="anonymous"
      @contextmenu.prevent
    >
      <p class="vjs-no-js">
        {{ "html5VideoNotSupportedPrepend" }}
        <a href="http://videojs.com/html5-video-support/" target="_blank">
          {{ "html5VideoNotSupportedAppend" }}
        </a>
      </p>
      <track
        v-for="(subtitle, idx) in subtitles"
        :key="subtitle.fileName"
        :src="subtitle.signedURL"
        :label="subtitle.label"
        :srclang="subtitle.lang"
        :default="idx === 0"
      />
    </video>
  </b-aspect>
</template>

<script>
import "video.js/dist/video-js.css"
import MediaPlayheadTracker from "../../../utils/MediaPlayheadTracker.class"

export default {
  name: "VideoPlayer",

  inheritAttrs: false,
  props: {
    source: {
      type: String,
      required: true,
    },
    loop: {
      type: Object,
      default: () => ({ active: false }),
    },
    playStartTime: Number,
    isPlaying: {
      type: Boolean,
    },
    subtitles: {
      type: Array,
      default: () => [],
    },
    chapter: {
      type: Object,
      default: () => {},
    },
    contentID: {},
  },
  data() {
    return {
      player: null,
      videoAspectRatio: 16 / 9,
      playPromise: null,
      mediaPlayheadTracker: null,
      startTimeToFirstFrame: 0,
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload)
    this.mediaPlayheadTracker = new MediaPlayheadTracker(
      "video",
      this.contentMetaData,
      this.chapter,
    )
  },
  mounted() {
    this._on("changeTime", this.changeTime)
    const videojs = require("video.js").default

    this.player = videojs(this.$refs.video, {
      playbackRates: [0.25, 0.5, 1, 2, 4],
      controlBar: {
        playbackRateMenuButton: false,
        fullscreenToggle: !this.$store.getters.fullScreenAsDefault,
        pictureInPictureToggle: !this.$store.getters.fullScreenAsDefault,
      },
      userActions: {
        doubleClick: () => {},
      },
    })
    this._on("pause", () => {
      if (this.player) this.player.pause()
    })
    this.player.on("loadedmetadata", () => {
      this.$nextTick(() => {
        this.$emit("loaded")
      })
    })

    this.initialize()
    this.loadAndPlay()
    window.addEventListener("keyup", this.fireTvKeyEvents)
  },
  beforeDestroy() {
    this._off("changeTime", this.changeTime)
    this._off("pause", () => {
      if (this.player) this.player.pause()
    })
    if (this.mediaPlayheadTracker) this.mediaPlayheadTracker.destroy()
    if (this.player) {
      this.player.dispose()
      this.player = null
    }
    window.removeEventListener("beforeunload", this.beforeWindowUnload)
    window.removeEventListener("keyup", this.fireTvKeyEvents)
  },

  methods: {
    fireTvKeyEvents(event) {
      switch (event.keyCode) {
        case 179:
          console.log(event.keyCode + " toggleChaptersModal")
          this._emit("toggleChaptersModal")
          break
        // skip forward
        case 228:
          console.log(event.keyCode + " changeTime")
          this._emit("changeTime", 15)
          break
        // rewind
        case 227:
          console.log(event.keyCode + " changeTime")
          this._emit("changeTime", -15)
          break
        // default:
        //   break
      }
    },
    changeTime(time) {
      if (this.player) {
        this.player.currentTime(this.player.currentTime() + time)
      }
    },
    bindVideoCanPlayThrough() {
      this.player.on("canplaythrough", () => {
        const start = this.startTimeToFirstFrame
        const end = Date.now()
        const elapsedSeconds = (end - start) / 1000
        this.$apiPublic("remember/it", {
          logType: "videoTimeToPlayThrough",
          description: `user media can play through: ${this.contentMetaData.id}`,
          mediaType: "video",
          context: "library",
          contentID: this.contentMetaData.id,
          contentProjectID: this.contentMetaData.projectID,
          src: this.source,
          elapsedSeconds,
        })
        this.startTimeToFirstFrame = 0
        this.player.off("canplaythrough")
      })
    },
    initialize() {
      this.bindVideoCanPlayThrough()
      this.player.on("loadedmetadata", () => {
        this.videoAspectRatio =
          this.player.videoWidth() / this.player.videoHeight()
      })
      this.player.on("play", () => {
        this.$emit("update:isPlaying", true)
      })
      this.player.on("timeupdate", () => {
        this.onTimeUpdate()
      })
      this.player.on("pause", () => {
        this.$emit("update:isPlaying", false)
      })
      this.player.on("seeked", () => {
        this.$emit("seeked", this.currentTime())
      })
      this.player.on("ended", () => {
        this.mediaPlayheadTracker.fileEnded()
        this.$emit("ended")
      })
      this.player.on("error", () => {
        this.$apiPublic("remember/it", {
          logLevel: "warn",
          logType: "mediaError",
          description: `user encountered a media error: ${this.contentMetaData.id}`,
          context: "library",
          mediaType: "video",
          contentID: this.contentMetaData.id,
          contentProjectID: this.contentMetaData.projectID,
          src: this.source,
          error: this.player.error(),
        })
      })
    },
    play() {
      this.playPromise = this.player.play()
      this.playPromise.then(() => {
        this.playPromise = null
      })
    },
    loadAndPlay() {
      if (!this.source) return
      const signedUrlParser = (url) => {
        const queryStringStartIndex = url.indexOf("?")
        if (queryStringStartIndex > -1) {
          return url.substring(0, queryStringStartIndex)
        }
        return url
      }

      let changedUrl

      // if the video URL hasn't been changed, then just change the chapter time, do not reload the video
      if (
        !this.oldSource ||
        signedUrlParser(this.oldSource) !== signedUrlParser(this.source)
      ) {
        changedUrl = true
        this.player.src(this.source)
        const loadVideoUrl = this.player.load()
        if (loadVideoUrl) {
          loadVideoUrl
            .then(() => {
              this.player.load()
              this.play()
            })
            .catch((err) => {
              console.log(err)
            })
        }
      } else {
        changedUrl = false
      }

      this.oldSource = this.source

      if (this.playStartTime) {
        this.currentTime(this.playStartTime)
        !changedUrl && this.play()
      }

      this.mediaStartSeconds = this.player.currentTime()
      this.startTimeToFirstFrame = Date.now()
    },
    currentTime(time) {
      return this.player.currentTime(time)
    },
    onTimeUpdate() {
      const currentTime = this.currentTime()
      this.$emit("timeUpdate", currentTime)
      if (this.mediaPlayheadTracker)
        this.mediaPlayheadTracker.timeUpdate(currentTime)
      if (this.loop.active) {
        if (this.loop.in && this.loop.out && currentTime >= this.loop.out) {
          this.currentTime(this.loop.in)
        }
      }
    },
    beforeWindowUnload() {
      if (this.mediaPlayheadTracker) this.mediaPlayheadTracker.destroy()
    },
  },
  computed: {
    contentMetaData() {
      return this.$store.state.contentMetaData
    },
  },
  watch: {
    chapter: {
      deep: true,
      handler(obj) {
        if (this.mediaPlayheadTracker)
          this.mediaPlayheadTracker.chapterUpdate(obj)
      },
    },

    source() {
      this.bindVideoCanPlayThrough()
      this.loadAndPlay()
    },
    playStartTime(playStartTime) {
      this.currentTime(playStartTime)
    },
    isPlaying(isPlaying) {
      // preventing loop
      if (this.playPromise) {
        console.log("isPlaying play promise is true")
        return
      }
      if (isPlaying) {
        this.play()
      } else {
        this.player.pause()
      }
    },
  },
}
</script>
